/* .wrapper-class {
  padding: 1rem;
  border: 2px solid #E5E5E5;
  max-height: 30%;
} */
.editor-class {
  background-color: #FFFFFF;
  padding: 1rem;
  border: 1px solid #E5E5E5;
  margin-bottom: 30px;
  height: 100%;
  max-height: 500px;
}
.toolbar-class {
  border: 1px solid #E5E5E5;
  background-color: #FFFFFF;
}
