.parent-span-down svg {
  fill: #FFF !important;
  width: 14px;
  height: 14px;
  padding: 0px;
  margin: 0px;
}

.parent-span-down:hover svg {
  fill: #000 !important;
}

.parent-span-up svg {
  fill: #FFF !important;
  width: 14px;
  height: 14px;
  padding: 0px;
  margin: 0px;
}

.parent-span-up:hover svg {
  fill: #000 !important;
}
