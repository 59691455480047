body {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

html, body {
  height: 100%;
}

/* fix hidden button */
button[hidden] {
  visibility: hidden;
}

.articleBodyText {
  font-family: 'PT Serif', serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
}

.articleBodyText > h1 {
  font-family: 'PT Serif', serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 15px;
}

.articleWrapperImage > h1 {
  font-family: 'PT Serif', serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 15px;
}

.articleQuoteText {
  font-family: 'Noto Serif', serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;

  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20%;
  padding-right: 20%;

  text-align: center;
}

.articleQuoteSecondaryText {
  font-family: 'Noto Serif', serif;
  font-size: 22px;
  font-weight: 500;
  font-style: italic;
  line-height: 35px;

  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20%;
  padding-right: 20%;

  text-align: center;
}

.articleQuoteImage {
  height: 38px;
  margin-bottom: 8px;
}

.articleQuoteSecondaryImage {
  height: 32px;
  margin-bottom: 8px;
}

.articleImage {
  width: 100%;
}

.articleImageTop {
  max-width: 100%;
  width: 115%;
}

.articleImageCaption {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 1.1;
}

.articleImagePhotographer {
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.03rem;
}

.articleWrapper::first-letter {
  initial-letter: 3;
  margin-right: 10px;
  margin-left: 2px;
  margin-top: 3px;
  font-family: 'Literata', serif;
}

.articleWrapperImage::first-letter {
  initial-letter: 4;
  margin-right: 10px;
  margin-left: 2px;
  margin-top: 3px;
  font-family: 'Literata', serif;
}

/* target firefox only, it doesn't support initial-letter rule */
@-moz-document url-prefix() {
  .articleWrapper :first-child::first-letter { font-size:250%; }
  .articleWrapper :first-child::first-line { line-height: 100%; }
}

.articleSubscriberTagText {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.1;
}

.articleEditoralTitle {
  font-Family: 'Noto Serif', serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}

.articleBlockTitle {
  font-Family: 'Noto Serif', serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
}

.articleBlockDate {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.articleBlockAuthor {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.articleBlockAuthorDescription {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.articleExtraNotesText {
  font-family: 'PT Serif', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}
